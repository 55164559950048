import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

declare const $: any;

export class Nav extends AbstractComponent
{
	public static selector: string = 'nav';
	protected dropdownCloseTimer;

	public init()
	{
		let component = this.getComponentElement();

		$(window).on('load', () => { // This is to prevent the flicker on hard page load
			component.find('.nav__dropdown').css('display', '');
		});

		component.on('click', function(e){
			e.stopPropagation();
		});

		component.on('mouseover mouseleave', '[target-open-selector], [trigger-open]', (e) => {
			let eventType = e.originalEvent.type;

			let element = $(e.currentTarget);
			let triggerOpenSelector = element.attr('trigger-open');
			let dropdownElement = component.find(`[target-open-selector='${triggerOpenSelector}']`);

		});

		component.find('[menu-item-trigger]').on('click', (e) => {
			let clicked = $(e.target);

			if (clicked.parents('[back]') !== undefined && clicked.parents('[back]').length === 0)
			{
				$(e.currentTarget).attr('open', 'true');
			}

		});

		component.find('[back]').on('click', (e) => {
			let parents = $(e.currentTarget).parents('[open]');

			parents.removeAttr('open');
		});

		component.find('[nav-toggle]').on('click', function(e){
			e.stopPropagation();

			if (component.attr('nav-open') == 'true')
			{
				component.removeAttr('nav-open');
				$('[nav-toggle]').removeAttr('open');
			}
			else
			{
				$('[nav-toggle]').attr('open', 'true');
				component.attr('nav-open', 'true');
			}
		}.bind(this));

		$('body').on('click', function(e) {
			component.removeAttr('nav-open');
		});

		$(window).on('scroll ready', (e) => {
			let scrollTop = $(e.currentTarget).scrollTop();
			if (scrollTop > 0)
			{
				this.getComponentElement().attr('scrolled', 'scrolled')
			}
			else
			{
				this.getComponentElement().removeAttr('scrolled')
			}
		});
	}

}

new ComponentLoader(Nav);
